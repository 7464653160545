:root {
    --primaryColor: #ef870f;
}

html {
    height: 100%;
}

.k-panelbar .k-link {
    color: white !important;
    background-color: var(--primaryColor) !important;
}

.k-panelbar .k-link.k-state-selected.k-state-hover {
    background-color: var(--primaryColor) !important;
}

.k-panelbar .k-link.k-state-selected {
    background-color: var(--primaryColor) !important;
}

.k-panelbar .k-link.k-state-hover {
    background-color: var(--primaryColor) !important;
}

.no-scrollbar .k-grid-header {
    padding: 0 !important;
}

.no-scrollbar .k-grid-content {
    overflow-y: visible;
}

.k-panelbar > .k-item > .k-link .k-icon, .k-panelbar > .k-item > .k-link .k-panelbar-item-icon, .k-panelbar > .k-panelbar-header > .k-link .k-icon, .k-panelbar > .k-panelbar-header > .k-link .k-panelbar-item-icon {
    color: white !important;
}

.levels-table .k-grid-header {
    display: none
}

.right-text-cell {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.k-grid-command {
    background: none !important;
}

.k-content > .k-animation-container {
    width: 100% !important;
}

.k-animation-container {
    z-index: 9999888 !important;
}

.k-tabstrip-items .k-link {
    flex: none !important;
}

.k-tabstrip-items-wrapper .k-item.k-state-active::after {
    border-color: var(--primaryColor) !important;
}

body {
    margin: 0;
    height: 100%;
}

.k-tabstrip-content.k-state-active, .k-tabstrip > .k-content.k-state-active {
    padding-right: 0;
}

::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(239, 135, 15, .3);
    border-radius: 100px
}

.k-combobox .k-dropdown-wrap .k-select {
    display: none;
}

.k-combobox .k-dropdown-wrap .k-clear-value {
    right: 8px;
}

.progress_pulsing {
    -webkit-animation: progressbar 1.5s infinite alternate both /* Chrome, Safari, Opera */ !important;
    animation: progressbar 1.5s infinite alternate both !important;
    border-radius: 30px !important;
}

.k-progressbar > .k-state-selected {
    border-radius: 30px !important;
    background-color: transparent !important;
}

@-webkit-keyframes progressbar {
    0% {
        background: #ef870f;
    }
    100% {
        background: #f4b15e
    }
}

@keyframes progressbar {
    0% {
        background: #ef870f;
    }
    100% {
        background: #f4b15e
    }
}

.timeSeries_2 > .k-grid-header col:nth-of-type(1) {
    width: 50%
}

.timeSeries_2 > .k-grid-table col:nth-of-type(1) {
    width: 50%
}

.timeSeries_2 > .k-grid-header col:nth-of-type(2) {
    width: 50%
}

.timeSeries_2 > .k-grid-table col:nth-of-type(2) {
    width: 50%
}

.timeSeries_4 > .k-grid-header col:nth-of-type(1) {
    width: 25%
}

.timeSeries_4 > .k-grid-header col:nth-of-type(1) {
    width: 25%
}

.timeSeries_4 > .k-grid-header col:nth-of-type(2) {
    width: 25%
}

.timeSeries_4 > .k-grid-header col:nth-of-type(2) {
    width: 25%
}

.timeSeries_4 > .k-grid-header col:nth-of-type(3) {
    width: 25%
}

.timeSeries_4 > .k-grid-header col:nth-of-type(3) {
    width: 25%
}

.timeSeries_4 > .k-grid-header col:nth-of-type(4) {
    width: 25%
}

.timeSeries_4 > .k-grid-header col:nth-of-type(4) {
    width: 25%
}
